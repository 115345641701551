<template>
    <div class="modal-background">
        <div class="modal-container">
            <div class="header-container">
                <label class="header">{{ this.title }}</label>
                <v-icon name="times" scale="1" class="close-button" @click="close(false)"/>
            </div>

            <div class="content-container">
                <slot name="content">{{ $t('general.areyousure') }}</slot>
            </div>

            <div class="modal-button-container">
                <input :disabled="isloading" type="button" class="button" @click="close(true)" :value="submitlabel"/>
                <input :disabled="isloading" type="button" class="button" @click="close(false)" :value="cancellabel"/>
            </div>
        </div>
    </div>
</template>

<script>
import 'vue-awesome/icons/times'

export default {
    props: {
        title: {
            type: String,
            default: 'Changes have been made'
        },
        isopen: {
            type: Boolean,
            default: false
        },
        submitlabel: {
            type: String,
            default: function() { return this.$t('general.yes')}
        },
        cancellabel: {
            type: String,
            default: function() { return this.$t('general.no')}
        },
        isloading: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        document.body.classList.toggle('noscroll', true)
    },
    methods: {
        close: function(success) {
            // prevent closing the dialog while something is loading
            if (!this.isloading) {
                document.body.classList.toggle('noscroll', false)
                this.$emit('close', success)
            }
        }
    }
}
</script>

<style scoped>
.modal-background {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
}

@media(min-width: 1001px) {
    .modal-container {
        width: 500px;
        left: 150px;
    }
}

@media(max-width: 1000px) {
    .modal-container {
        width: 100%;
    }
}

.modal-container {
    background: var(--color-fg-default);
    border-radius: var(--setting-border-radius-big-default);
    height: fit-content;
    margin: auto;
    top: 50%;
    position: relative;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 20px;
    box-sizing: border-box;
    max-width: 500px;
}

.header-container {
    color: var(--color-font-header-default);
    font-weight: bold;
    font-size: 1.2em;
    text-align: left;
    border-bottom: 1px solid var(--color-border-dim-default);
}

.content-container {
    margin-top: 20px;
}

.modal-button-container {
    width: fit-content;
    text-align: left;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
}

.close-button {
    float: right;
    color: var(--color-highlight-greyscale);
}

.close-button:hover {
    cursor: pointer;
}
</style>