export default {
    install(Vue) {
        const HQ_SESSION = '__hqsession__'
        const HQ_DEFAUL_CLIENT = '__hqclient__'
        var sessionchecker

        function resetSessionChecker() {
            clearInterval(sessionchecker)
            Vue.$toast.clear()

            // setup session checker
            // --> check every 60 seconds
            // --> warn 5 minutes, 2 minutes and 1 minute before
            // --> auto logout after session timeout
            let warnlevel = 0
            // TODO:
            const errormsg = 'Sie werden in einer Minute abgemeldet!' // this.$t('session.tobeloggedout')
            const warnmsg = 'Ihr Session läuft in 2 Minuten ab' // this.$t('session.warning')
            const infomsg = 'Ihr Session läuft in 5 Minuten ab' // this.$t('session.info')
            const vue = Vue
            sessionchecker = setInterval(function() {
                const sessionexpiresin = vue.sessionExpiresIn()

                if (warnlevel === 2 && sessionexpiresin < 1 * 60 * 1000) {
                    warnlevel = 3
                    vue.$toast.open({
                        message: errormsg,
                        type: 'error',
                        pauseOnHover: false,
                        position: 'bottom-right',
                        duration: 1 * 60 * 1000 // keep open for 1 minute, until logout
                    })
                } else if (warnlevel === 1 && sessionexpiresin < 2 * 60 * 1000) {
                    warnlevel = 2
                    vue.$toast.open({
                        message: warnmsg,
                        type: 'warning',
                        pauseOnHover: false,
                        position: 'bottom-right',
                        duration: 1 * 60 * 1000 // keep open for 1 minute, until next stage
                    })
                } else if (warnlevel === 0 && sessionexpiresin < 5 * 60 * 1000) {
                    warnlevel = 1
                    vue.$toast.open({
                        message: infomsg,
                        type: 'info',
                        pauseOnHover: false,
                        position: 'bottom-right',
                        duration: 3 * 60 * 1000 // keep open for 3 minutes, until next stage
                    })
                } else if (vue.hasSessionExpired()) {
                    this.$router.push({ name: 'login' })
                    vue.clearSessionData()
                    warnlevel = 0
                }
            }, 1000 * 1)
        }

        Vue.setSessionData = function(creationtime, token, client, clientid, user, corporationid) {
            const sessionData = {
                created: creationtime,
                token: token,
                client: client,
                clientid: clientid,
                username: user,
                corporationid: corporationid
            }

            // save the client that was originally used
            if (!localStorage[HQ_SESSION]) {
                localStorage[HQ_DEFAUL_CLIENT] = client
            }

            localStorage[HQ_SESSION] = JSON.stringify(sessionData)

            resetSessionChecker()
        }

        Vue.getDefaultClient = function() {
            return localStorage[HQ_DEFAUL_CLIENT]
        }

        Vue.resetDefaultClient = function() {
            localStorage.removeItem(HQ_DEFAUL_CLIENT);
        }

        Vue.getSessionData = function() {
            if (localStorage[HQ_SESSION]) {
                return JSON.parse(localStorage[HQ_SESSION])
            } else {
                return undefined
            }
        }

        Vue.hasSession = function() {
            return Vue.getSessionData() ? true : false
        }

        Vue.getSessionToken = function() {
            return Vue.getSessionData().token
        }

        Vue.clearSessionData = function() {
            localStorage.removeItem(HQ_SESSION);
            clearInterval(sessionchecker)
        }

        Vue.hasSessionExpired = function() {
            return Vue.sessionExpiresIn() < 0
        }

        Vue.sessionExpiresIn = function() {
            const minutestotimeout = process.env.VUE_APP_TO_TIMEOUT_MINUTES
            const mstotimeout      = minutestotimeout * 60 * 1000
            const currentdatetime  = new Date()
            const sessioncreated   = new Date(Vue.getSessionData().created)
            const timeinsession = currentdatetime - sessioncreated
            return mstotimeout - timeinsession
        }

        Vue.extendSession = function() {
            // shouldn't matter, but just in case
            if (localStorage[HQ_SESSION]) {
                let currentSession = Vue.getSessionData()
                currentSession.created = new Date().getTime()
                localStorage[HQ_SESSION] = JSON.stringify(currentSession)
                resetSessionChecker()
            }
        }
    }
}