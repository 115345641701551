<template>
  <div id="page-container">
    <div class="card-container-wrapper">
      <div
        class="card-container client-container"
        :class="{ 'shimmer big': isClientLoading }"
      >
        <div v-if="isClientLoading" class="loading-container">
          <LoadingSpinner />
        </div>
        <div v-if="!isClientLoading" class="client-content-container">
          <div class="client-title-container">
            <label>{{ $t("dashboard.client") }}</label>
          </div>

          <div class="client-content">
            <div class="client-content-half left">
              <p>{{ this.client.name }}</p>
              <p>{{ this.client.abbreviation }}</p>
              <p>{{ this.client.email }}</p>
              <p>{{ this.client.phone }}</p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="card-container client-container"
        :class="{ 'shimmer big': isCorpLoading }"
      >
        <div v-if="isCorpLoading" class="loading-container">
          <LoadingSpinner />
        </div>
        <div v-if="!isCorpLoading" class="client-content-container">
          <div class="client-title-container">
            <label>{{ $t("dashboard.corporation") }}</label>
          </div>

          <div class="client-content">
            <div class="client-content-half left">
              <p>{{ this.corporation.nr }}</p>
              <p>{{ this.corporation.name }}</p>
              <p>
                {{ this.corporation.street }} {{ this.corporation.housenr }}
              </p>
              <p>{{ this.corporation.zip }} {{ this.corporation.city }}</p>
            </div>
          </div>
        </div>
      </div>

      <div
        class="card-container client-container"
        :class="{ 'shimmer big': isContactsLoading }"
        v-if="isCorpLoading || (this.corporation && (this.corporation.supervisor1 || this.corporation.supervisor2))"
      >
        <div v-if="isContactsLoading" class="loading-container">
          <LoadingSpinner />
        </div>

        <div v-if="!isContactsLoading" class="client-content-container" >
          <div class="client-title-container">
            <label>{{ $t("dashboard.contact") }}</label>
          </div>

          <div
            class="client-content"
            v-for="contact in contactList"
            :key="contact.Email"
          >
            <div v-if="!isContactsLoading" class="client-content-half left">
              <p>{{ contact.Anrede }}</p>
              <p>{{ contact.Vorname }}</p>
              <p>{{ contact.Nachname }}</p>
              <p>{{ contact.Email }}</p>
              <p>{{ contact.Telefon }}</p>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="appList.length > 0 && !isAppLoading"
        class="top-button-container"
      >
        <div class="reject">
          <input
            :disabled="isAppLoading"
            type="button"
            class="button"
            :value="$t('application.buttons.rejectall')"
            @click="rejectAllApplications()"
          />
        </div>
        <div class="accept">
          <input
            :disabled="isAppLoading"
            type="button"
            class="button"
            :value="$t('application.buttons.acceptall')"
            @click="acceptAllApplications()"
          />
        </div>
      </div>

      <transition-group name="list" tag="div">
        <div
          class="card-container"
          v-for="application in appList"
          :key="application.Antragsnumme"
          :class="{ shimmer: isAppLoading }"
        >
          <div class="floating-wrapper" v-if="!isAppLoading && !isClassesLoading">
            <FloatingInput
              :title="$t('application.Antragsnummer')"
              inputStyle="text-align: right;"
              v-model="application.Antragsnumme"
              :isdisabled="true"
            />
            <FloatingInput
              :title="$t('application.Antragsart')"
              inputStyle="text-align: right;"
              v-model="application.Antragsart"
              :isdisabled="true"
            />
            <FloatingInput
              :title="$t('application.NameKVP')"
              inputStyle="text-align: right;"
              :value="application.NameKVP + ' (' + application.Email + ')'"
              :isdisabled="true"
            />
            <FloatingInput
              :title="$t('application.Name')"
              inputStyle="text-align: right;"
              :value="application.Nachname + ', ' + application.Vorname"
              :isdisabled="true"
            />
            <FloatingDate
              :title="$t('application.Geburtsdatum')"
              inputStyle="text-align: right;"
              :value="getDateString(application.GeburtsdatumDate)"
              :isdisabled="true"
            />
            <FloatingInput
              :title="$t('application.Adresse')"
              inputStyle="text-align: right;"
              :value="
                application.Adresse.Strasse +
                ' ' +
                application.Adresse.HausNr +
                '' +
                application.Adresse.Zusatz +
                ', ' +
                application.Adresse.PLZ +
                ' ' +
                application.Adresse.Ort
              "
              :isdisabled="true"
            />
            <FloatingInput
              :title="$t('application.Produkt')"
              inputStyle="text-align: right;"
              v-model="application.Produktbezeichnung"
              :isdisabled="true"
            />
            <FloatingDate
              :title="$t('application.Gueltigkeitsbegin')"
              inputStyle="text-align: right;"
              :value="getDateString(application.Gueltigkeitsbegin)"
              :isdisabled="true"
            />
            <FloatingDropdown
              :title="$t('application.Klasse')"
              inputStyle="text-align: right;"
              v-model="application.Klasse"
              :optionlist="classList"
              :selected="application.Klasse.toString()"
              @change="classChanged($event, application)"
              v-if="!isAppLoading && classList.length > 0"
            />
            <FloatingInput
              :title="$t('application.Personalnummer')"
              inputStyle="text-align: right;"
              v-model="application.Personalnummer"
              :isdisabled="true"
            />
            <FloatingInput
              :title="$t('application.Bemerkung')"
              inputStyle="text-align: right;"
              v-model="application.Bemerkung"
            />
          </div>
          <div class="button-container">
            <div class="reject">
              <input
                :disabled="isAppLoading"
                type="button"
                class="button"
                :value="$t('application.buttons.reject')"
                @click="rejectApplication(application)"
              />
            </div>
            <div class="accept">
              <input
                :disabled="isAppLoading"
                type="button"
                class="button"
                :value="$t('application.buttons.accept')"
                @click="acceptApplication(application)"
              />
            </div>
          </div>
        </div>
      </transition-group>

      <div v-if="appList.length === 0 && !isAppLoading" class="empty">
        <label>{{ $t("application.noapplications") }}</label>
      </div>
    </div>
    <Modal
      v-if="showrejectareyousure"
      :isopen="showrejectareyousure"
      @close="hideRejectAreYouSure"
      :title="$t('general.areyousure')"
    >
      <template v-slot:content>{{ $t("general.areyousurereject") }}</template>
    </Modal>
    <Modal
      v-if="showacceptareyousure"
      :isopen="showacceptareyousure"
      @close="hideAcceptAreYouSure"
      :title="$t('general.areyousure')"
    >
      <template v-slot:content>{{ $t("general.areyousureaccept") }}</template>
    </Modal>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'

import "vue-awesome/icons/ticket-alt"
import "vue-awesome/icons/exchange-alt"
import "vue-awesome/icons/user"
import "vue-awesome/icons/money-check-alt"
import "vue-awesome/icons/portrait"
import "vue-awesome/icons/balance-scale-right"
import "vue-awesome/icons/tasks"
import "vue-awesome/icons/receipt"

import Modal from "../components/systems/elements/Modal.vue"
import FloatingDate from "../components/systems/elements/FloatingDate"
import FloatingInput from "../components/systems/elements/FloatingInput"
import LoadingSpinner from "../components/systems/elements/loadingSpinner"
import FloatingDropdown from "../components/systems/elements/FloatingDropdown"

import getEnv from '@/utils/env.js'

export default {
    name: "ConfirmationPage",
    mounted() {
        this.init()
    },
    methods: {
        init: function() {
            this.getClient()
            this.getCorporation()
            this.getApplications()
            this.getClasses()
        },
        getApplications: function () {
            this.isAppLoading = true

            var sessionData = Vue.getSessionData()
            
            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
            url += "/" + sessionData.clientid
            url += "/" + "corperations"
            url += "/" + sessionData.corporationid
            url += "/applications"

            axios.get(url)
            .then((response) => {
                var responseBody = response.data
                var status = responseBody.status
                
                if (status.ErrorCode != 0) {
                    Vue.$toast.error(status.ErrorMsg)
                } else {
                    this.appList = responseBody.data.applicationslist
                }

                this.isAppLoading = false
            })
            .catch((err) => {
                // TODO: implement no data found view
                Vue.$toast.error(JSON.stringify(err))
            });
        },
        getClasses: function () {
            this.isClassesLoading = true

            var sessionData = Vue.getSessionData()

            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
            url += "/" + sessionData.clientid
            url += "/" + "corperations"
            url += "/" + sessionData.corporationid
            url += "/classes"

            axios.get(url)
            .then((response) => {
                var responseBody = response.data
                var status = responseBody.status
                
                if (status.ErrorCode != 0) {
                    Vue.$toast.error(status.ErrorMsg)
                } else {
                    this.classList = responseBody.data.classeslist?.map((entry) => {
                        return {
                            displayid: entry.IDKlasse,
                            displayname: entry.Bezeichnung,
                        }
                    }) || []

                    this.classList.push({ displayid: "", displayname: " " })

                    var collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'})
                    this.classList = this.classList.sort(collator.compare)
                }

                this.isClassesLoading = false;
            })
            .catch((err) => {
                // TODO: implement no data found view
                Vue.$toast.error(JSON.stringify(err))
            });
        },
        getClient: function () {
            this.isClientLoading = true;

            var sessionData = Vue.getSessionData()

            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
            url += "/" + sessionData.clientid
            url += "/data"

            axios.get(url)
            .then((response) => {

                var responseBody = response.data
                var status = responseBody.status

                if (status.ErrorCode != 0) {
                    Vue.$toast.error(status.ErrorMsg)
                } else {
                    this.client = responseBody.data
                }

                this.isClientLoading = false
            })
            .catch((err) => {
                // TODO: implement no data found view
                Vue.$toast.error(err)
            });
        },
        getCorporation: function () {
            this.isCorpLoading = true

            var sessionData = Vue.getSessionData()

            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
            url += "/" + sessionData.clientid
            url += "/corporations"
            url += "/" + sessionData.corporationid

            axios.get(url)
            .then((response) => {
                var data = response.data.data
                var status = response.data.status

                if (status.ErrorCode != 0) {
                    Vue.$toast.error(status.ErrorMsg)
                } else {
                    this.corporation = data
                }

                this.getContacts()
                this.isCorpLoading = false
            })
            .catch((err) => {
                // TODO: implement no data found view
                Vue.$toast.error(err)
            });
        },
        getContacts: function () {
            this.isContactsLoading = true

            if (this.corporation.supervisor1 != 0) {
                this.getContact(this.corporation.supervisor1)
            }

            if (this.corporation.supervisor2 != 0) {
                this.getContact(this.corporation.supervisor2)
            }

            this.isContactsLoading = false
        },
        getContact: function (aContact) {
            this.isContactsLoading = true

            var sessionData = Vue.getSessionData()

            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
            url += "/" + sessionData.clientid
            url += "/contacts"
            url += "/" + aContact

            axios.get(url)
            .then((response) => {
                var responseBody = response.data
                var status = responseBody.status

                if (status.ErrorCode != 0) {
                    this.emitError(status.ErrorMsg)
                } else {
                    this.contactList.push(responseBody.data)
                }
                this.isContactsLoading = false
            })
            .catch((err) => {
                // TODO: implement no data found view
                Vue.$toast.error(err)
            });
        },
        getDateString: function (aDate) {
            var date = new Date(aDate);
            // https://stackoverflow.com/a/3605248/9368637
            // getMonth() ist starting by 0
            return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2)
        },
        acceptApplication: function (application) {
            Vue.$toast.info("Accepting Application No.: " + application.Antragsnumme)

            var sessionData = Vue.getSessionData()

            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
            url += "/" + sessionData.clientid
            url += "/" + "corperations"
            url += "/" + sessionData.corporationid
            url += "/applications/accept"

            var body = {
                applications: [],
            }

            let data = {
                TOWSAntragsDaten: application,
            }

            body.applications.push(data)

            axios.post(url, body).then((response) => {
                var status = response.data.status

                if (status.ErrorCode != 0) {
                    Vue.$toast.error(status.ErrorMsg)
                } else {
                    this.appList.splice(this.appList.indexOf(application), 1)
                }
            })
        },
        rejectApplication: function (application) {
            Vue.$toast.info("Rejecting Application No.: " + application.Antragsnumme)

            var sessionData = Vue.getSessionData()

            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
            url += "/" + sessionData.clientid
            url += "/" + "corperations"
            url += "/" + sessionData.corporationid
            url += "/applications/reject"

            var body = {
                applications: [],
            }

            let data = {
                TOWSAntragsDaten: application,
            }

            body.applications.push(data)

            axios.post(url, body).then((response) => {
                var status = response.data.status

                if (status.ErrorCode != 0) {
                Vue.$toast.error(status.ErrorMsg)
                } else {
                this.appList.splice(this.appList.indexOf(application), 1)
                }
            });
        },
        acceptAllApplications: function () {
            this.showacceptareyousure = true
        },
        hideAcceptAreYouSure: function (submit) {
            if (submit) {
                for (const app of this.appList) {
                this.acceptApplication(app);
                }
                this.showacceptareyousure = false
            } else {
                this.showacceptareyousure = false
            }
        },
        rejectAllApplications: function () {
            this.showrejectareyousure = true
            },
        hideRejectAreYouSure: function (submit) {
            if (submit) {
                for (const app of this.appList) {
                this.rejectApplication(app)
                }
                this.showrejectareyousure = false
            } else {
                this.showrejectareyousure = false
            }
        },
        classChanged: function (event, application) {
            application.Klasse = event.displayname
        },
    },
    data() {
        return {
            client: {
                name: null,
                abbreviation: null,
                email: null,
                phone: null,
            },
            corporation: {
                nr: null,
                housenr: null,
                street: null,
                name: null,
                zip: null,
                city: null,
            },
            contact: {
                Anrede: null,
                Vorname: null,
                Nachname: null,
                Email: null,
                Telefon: null,
            },
            showrejectareyousure: false,
            showacceptareyousure: false,
            isLoading: true,
            isAppLoading: true,
            isClientLoading: true,
            isCorpLoading: true,
            isContactsLoading: true,
            isClassesLoading: true,
            appList: [],
            classList: [],
            contactList: [],
        }
    },
    computed: {},
    components: {
        LoadingSpinner,
        FloatingInput,
        FloatingDate,
        FloatingDropdown,
        Modal,
    },
}
</script>

<style scoped>
.card-container.client-container {
    width: 300px;
    height: 200px;
    margin: 20px;
    background-color: var(--color-fg-default);
    box-shadow: var(--shadow-default);
    border-radius: var(--setting-border-radius-big-default);
    overflow: hidden;
    position: relative;
    text-align: left;
    transition: transform 0.2s;
}

.client-content {
    height: 90%;
    display: flex;
}

.client-content-half {
    width: 100%;
    text-align: center;
}

.client-content-half > p:nth-of-type(1) {
    margin-top: 15px;
    margin-bottom: 20px;
    font-weight: bold;
}

.client-content-container {
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.client-content-divider {
    height: 85%;
    border: 1px solid var(--color-border-bright-default);
    margin-top: 10px;
}

.client-title-container {
    font-size: 1.3em;
    height: 25px;
    border-bottom: 1px solid var(--color-font-header-default);
}

.loading-container {
    top: calc(50% - 40px);
    position: relative;
}

.card-container {
    width: 980px;
    height: 230px;
}

.card-container:hover {
    background: var(--color-fg-default);
}

.title-container {
    height: 20px;
    line-height: 20px;
    font-size: 1em;
}

.count-container {
    margin-top: 20px;
    height: 40px;
    width: 100%;
    background-color: var(--color-highlight-default);
    color: var(--color-font-highlight-default);
    line-height: 40px;
    font-size: 1.5em;
}

.button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.top-button-container > .accept,
.button-container > .accept {
    color: green;
}

.top-button-container > .reject,
.button-container > .reject {
    color: red;
}

.button-container > .reject {
    margin-right: 20px;
}

.empty,
.top-button-container {
    width: 980px;
    display: flex;
    justify-content: space-evenly;
    background: #fff;
    margin: 10px 0;
    box-shadow: var(--shadow-default);
    border-radius: var(--setting-border-radius-big-default);
    padding: 10px;
    box-sizing: border-box;
}

.top-button-container > .button {
    padding: 0 20px;
    min-width: 200px;
    height: 50px;
    text-transform: uppercase;
}

.button-container > .accept::before,
.top-button-container > .accept::before {
    content: "\2713";
    color: green;
    font-size: 1.3em;
    font-weight: bold;
}
.button-container > .reject::before,
.top-button-container > .reject::before {
    content: "\2716";
    color: red;
    font-size: 1.3em;
}

.floating-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 10px;
}
.floating-wrapper > div {
    width: 32%;
    padding: 5px;
    box-sizing: border-box;
}

/* .list-item {
  display: inline-block;
  margin-right: 10px;
} */

.list-enter-active,
.list-leave-active {
    transition: all 1s;
}

.list-enter {
    opacity: 0;
    transform: translateX(-30px);
}

.list-leave-to /* .list-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateX(30px);
}

/*
.list-complete-item {
    transition: all 1s;
    display: inline-block;
    margin-right: 10px;
}

.list-complete-enter, .list-complete-leave-to {
    opacity: 0;
    transform: translateY(30px);
}

.list-complete-leave-active {
    position: absolute;
}

.list-move {
    transition: transform 1s;
}
*/
</style>