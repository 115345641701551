<template>
    <div class="container">
        <div class="logo-container">
            <div class="img-container">
                <img class="logo" src="../assets/img/highq_logo.png" @click="toggleTheme"/>
            </div>
        </div>

        <div class="title-container" @click="goHome">
            <div class="title">{{ title }}</div>
        </div>

        <div class="actions-narrow" v-if="withmenu">
            <div>
                <v-icon name="sign-out-alt" class="logout" @click="logout"/>
            </div>
            <div>
                <v-icon name="key" class="change-password" @click="changePassword" />
            </div>
        </div>

        <Modal v-if="showchangepassword" :isopen="showchangepassword" :isloading="isloadingchangepassword" @close="hideModal" :title="$t('navbar.changepassword')" :submitlabel="$t('navbar.buttons.confirm')" :cancellabel="$t('navbar.buttons.abort')">
            <template v-slot:content>
                <FloatingInput :title="$t('navbar.oldpassword')" :isloading="isloadingchangepassword" :error="$v.oldpassword.$error" v-model="oldpassword" :isrequired="true" :password="true" />
                <div class="spacer"></div>
                <FloatingInput :title="$t('navbar.newpassword')" :isloading="isloadingchangepassword" :error="$v.newpassword.$error" v-model="newpassword" :isrequired="true" :password="true" />
                <div class="spacer"></div>
                <FloatingInput :title="$t('navbar.newpasswordconfirm')" :isloading="isloadingchangepassword" :error="$v.newpasswordconfirm.$error" v-model="newpasswordconfirm" :isrequired="true" :password="true" />
                <div class="spacer"></div>
            </template>
        </Modal>
    </div>
</template>

<script>
import 'vue-awesome/icons/bars'
import 'vue-awesome/icons/key'
import 'vue-awesome/icons/sign-out-alt'

import Modal from './systems/elements/Modal.vue'
import FloatingInput from './systems/elements/FloatingInput.vue'
import ClickOutside from 'vue-click-outside'
import { required, sameAs } from 'vuelidate/lib/validators'

import getEnv from '@/utils/env.js'

import Vue from 'vue'
import axios from 'axios'

export default {
    name: 'NavBar',
    props: {
        title: {
            type: String,
            default: 'Vuepoc'
        }
    },
    data() {
        return {
            showchangepassword: false,
            isloadingchangepassword: false,
            oldpassword: null,
            newpassword: null,
            newpasswordconfirm: null
        }

    },
    validations: {
        oldpassword: { required },
        newpassword: { required },
        newpasswordconfirm: {
            required,
            sameAsPassword: sameAs('newpassword')
        }
    },
    methods: {
        hideModal: function(submit) {
            if (submit) {
                this.$v.$touch()

                console.log(this.$v)

                if (this.$v.$anyError) {
                    return
                }

                this.isloadingchangepassword = true

                let url = getEnv('VUE_APP_MIDDLEWARE_URL')
                url += '/changepassword/' + Vue.getSessionData().client

                const body = {
                    username: Vue.getSessionData().username,
                    oldpassword: this.oldpassword,
                    newpassword: this.newpassword,
                    newpasswordconfirm: this.newpasswordconfirm
                }

                axios.post(url, body)
                .then(response => {
                    var status = response.data.status

                    if (status.ErrorCode !== 0) {
                        Vue.$toast.error(status.ErrorMsg)
                    } else {
                        Vue.$toast.info(this.$t('navbar.changepasswordsuccess'))
                        this.showchangepassword = false
                        Vue.clearSessionData()
                        this.$router.push({ name: 'login' })
                    }

                    this.isloadingchangepassword = false
                })
                .catch(err => {
                    Vue.$toast.error(err)
                    this.isloadingchangepassword = false
                })
            } else {
                this.showchangepassword = false
            }
        },
        logout: function() {
            Vue.clearSessionData()
            let login = '/login'
            if (Vue.getDefaultClient()) {
                login += '?client=' + Vue.getDefaultClient()
            }
            this.$router.push(login)
        },
        changePassword: function() {
            this.showchangepassword = true
        },
        goHome: function() {
            this.$router.push({ name: 'root' })
        },
        // theme related stuff
        toggleTheme: function() {
            let active = document.querySelector("#alt-theme");
            if (!active) {
                this.addLightTheme()
            } else {
                this.removeLightTheme()
            }
        },
        addLightTheme: function() {
            let themeEl = document.createElement("link")
            themeEl.setAttribute("rel", "stylesheet")
            themeEl.setAttribute("href", "/css/alt-theme.css")
            themeEl.setAttribute("id", "alt-theme")

            let docHead = document.querySelector("html")
            docHead.append(themeEl)
            localStorage["hqtheme"] = JSON.stringify(true)
        },
        removeLightTheme: function() {
            let themeEl = document.querySelector("#alt-theme")
            let parentNode = themeEl.parentNode
            parentNode.removeChild(themeEl)
            localStorage["hqtheme"] = JSON.stringify(false)
        },
    },
    computed: {
        withmenu() {
            return this.$route.meta.menu
        }
    },
    components: {
        Modal,
        FloatingInput
    },
    directives: {
        ClickOutside
    }
}
</script>

<style scoped>
.container {
    text-align: left;
    height: fit-content;
    background: var(--color-fg-default);
    border-color: var(--color-border-bright-default);
    z-index: 100;
}

.item-container {
    width: 90%;
    height: 40px;
    margin: 5px 15px;
    line-height: 40px;
    font-size: 1em;
}

.item-container:hover > div:not(.router-link-active) {
    cursor: pointer;
    background-color: var(--color-hover-highlight-10);
    border-radius: calc(var(--setting-border-radius-big-default) + 10px);
}

.menu-container {
    margin: 10px;
    margin-top: 0px;
    margin-left: 0px;
    background-color: var(--color-fg-default);
    padding-top: 20px;
    box-shadow: var(--shadow-default);
}

.item-container:hover,
.item-container > label:hover {
    cursor: pointer;
}

/* header */
.logo-container {
    margin-left: 50px;
    height: 60px;
    width: fit-content;
    float: left;
    display: table;
}

.img-container {
    display: table-cell;
    vertical-align: middle;
}

.logo-container .logo {
    height: 45px;
}

.title-container {
    float: left;
    margin-left: 25px;
    font-size: 1.2em;
    width: 170px;
    height: 60px;
    display: table;
}

.title-container:hover {
    cursor: pointer;
}

.menu-icon:hover {
    cursor: pointer;
}

.title {
    display: table-cell;
    vertical-align: middle;
}

.actions-narrow {
    visibility: hidden;
    display: flex;
    z-index: 5;
}

.actions {
    position: absolute;
    bottom: 15px;
    left: 5px;
    z-index: 5;
    display: flex;
}

.actions-narrow > div > svg,
.actions > div > svg {
    width: 30px;
    height: 30px;
    padding: 5px;
    box-sizing: border-box;
    border-radius: var(--setting-border-radius-big-default);
}

.actions-narrow > div > svg:hover,
.actions > div > svg:hover {
    background: var(--color-hover-contrast-10);
    cursor: pointer;
}

.logout {
    color: red;
}

.change-password {
    padding: 6px !important;
    margin-left: 10px;
}

.spacer {
    margin-top: 20px;
}


.container {
    width: 100%;
    height: 60px;
    position: fixed;
    box-shadow: var(--shadow-default);
}

.menu-toggle-container {
    position: absolute;
    /* make space for the icon which toggles the theme */
    width: calc(100% - 100px);
    text-align: right;
    height: 60px;
    line-height: 60px;
    padding-right: 20px;
    box-sizing: border-box;
    /* make space for the icon which toggles the theme */
    left: 100px;
}

.menu-container {
    width: 100%;
    height: fit-content;
    border-radius: 0 0 var(--setting-border-radius-big-default) var(--setting-border-radius-big-default);
    overflow: hidden;
    visibility: hidden;
}

.menu-container-wrapper {
    visibility: hidden;
}

.item-container {
    text-align: center;
}

.menu-container.menu-toggled {
    visibility: visible;
}

.router-link-active {
    margin: 0 auto;
    border-radius: calc(var(--setting-border-radius-big-default) + 10px);
    box-shadow: var(--shadow-default);
}

.actions-narrow {
    position: absolute;
    top: 8px;
    right: 70px;
    left: unset;
    visibility: visible;
}

</style>