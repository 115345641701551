<template>
  <div id="page-container">
      <LoginWidget @error="displayError" @success="onSuccess" :title="$t('login.header.login')" :loadingText="$t('login.loading')" />
  </div>
</template>

<script>
import Vue from 'vue'
import LoginWidget from '../components/LoginWidget.vue'

export default {
    name: 'LoginPage',
    components: {
        LoginWidget
    },
    methods: {
        displayError: function(msg) {
            Vue.$toast.error(msg)
        },
        onSuccess: function() {
            // replace, not push do that the user can't "navigate back" to the login
            // this.$router.push({ name: 'dashboard' })
            this.$router.replace('dashboard')
        }
    }
}
</script>
