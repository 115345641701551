<template>
    <div class="page-container">
        <div v-if="error" class="content-container round-container error-container" v-show="!isloading">
            <label class="heading">{{ $t('confirmation-link-landing.error') }}</label>
            <Error class="error"/>
            <label class="info">{{ $t('confirmation-link-landing.error-sub-text') }}</label>
        </div>
        <div v-else class="content-container round-container" v-show="!isloading">
            <label class="heading">{{ $t('confirmation-link-landing.success') }}</label>
            <Check class="check"/>
            <label class="info">{{ $t('confirmation-link-landing.success-sub-text') }}</label>
        </div>

        <Transition name="fade">
            <div class="loading" v-show="this.isloading">
                <div class="spinner"></div>
                <div class="label">{{ $t('confirmation-link-landing.loading') }}</div>
            </div>
        </Transition>
    </div>
</template>

<script>
import axios from 'axios'
import getEnv from '@/utils/env.js'

import Check from '@/components/systems/elements/Check.vue'
import Error from '@/components/systems/elements/Error.vue'

export default {
    name: 'confirmation-link-landing-page',
    data() {
        return {
            error: undefined,
            isloading: false
        }
    },
    components: {
        Check,
        Error
    },
    mounted() {
        this.isloading = true

        const token = this.$route.query.token || this.$route.query.Token

        if (!token) {
            this.error = true
            this.isloading = false
            console.log('no-token')
            return
            // TODO: error?
        }

        
        let accepturl = getEnv('VUE_APP_MIDDLEWARE_URL')
        accepturl += '/contracts/' + token
        accepturl += '/accept'

        axios.get(accepturl)
        .then(() => {
            this.isloading = false
        })
        .catch(err => {
            if (err.response) {
                console.log(err.response.statusText)
            } else {
                console.log(err)
            }

            this.isloading = false
            this.error = true
            
        })
    },
}
</script>

<style scoped>
.page-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.content-container {
    width: 100%;
    color: #333;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: #fff;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 5px rgb(100 100 150 / 30%);
}

.content-container > .heading {
    font-weight: bold;
    font-size: 2em;
}

.content-container > .error,
.content-container > .check {
    margin: 50px 0;
}

.content-container > .info {
    font-weight: bold;
    font-size: 1.5em;
}

.content-container > .confirmation {
    font-size: 1.2em;
    margin-top: 20px;
}

.content-container > .email {
    margin: 20px 0;
    font-weight: bold;
    font-size: 1.4em;
    color: #064A6C;
}

.content-container > .back {
    margin-top: 50px;
    padding: 10px;
    color: #fff;
    background: #064A6C;
}

.content-container > .back:hover {
    background: #064A6Ccc;
}

.error-container {
    border: 4px solid red;
    border-left: none;
    border-right: none;
}

/* ----- ----- ----- ----- */
.loading {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: #000000cc;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 999;
}

.loading > .spinner {
    width: 50px;
    height: 50px;
    border: 4px solid #fff;
    border-bottom-color: #ffffff33;
    border-radius: 50%;

    animation-name: spin;
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.loading > .label {
    color: #fff;
    font-size: 1.4em;
    font-weight: bold;
    margin-top: 10px;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>