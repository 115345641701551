<template>
    <svg class="cross" viewBox="0 0 52 52">
        <circle class="cross-circle" cx="26" cy="26" r="25" fill="none"/>
        <path class="cross-check" stroke-width="4" fille="none" d="M 14,14 l 24,24 M 38,14 l -24,24"/>
    </svg>
</template>

<script>
export default {
    name: 'Check'
}
</script>

<style scoped>
.cross-circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #ff0000;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.cross {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    /* display: block; */
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    /* box-shadow: inset 0px 0px 0px #7ac142; */
    animation: fill .4s ease-out .4s forwards, scale .3s ease-in-out .9s both;
}

.cross-check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}
@keyframes scale {
    0%, 100% {
        transform: none;
    }
    65% {
        transform: scale3d(1.5, 1.5, 1);
    }
}
@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #ff0000;
    }
}
</style>