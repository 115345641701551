import Vue from 'vue'
import VueToast from 'vue-toast-notification'

const options = {
    position: 'top',
    duration: 7500 // 7.5sec
}

// register and setup toast component
Vue.use(VueToast, options)

export default VueToast