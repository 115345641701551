import Vue from 'vue'
import App from './App.vue'
import i18n from './plugins/i18n'
import Icon from 'vue-awesome/components/Icon'
import dotenv from 'dotenv'
import dotenvExpand from 'dotenv-expand'
import router from './plugins/router'
import vuelidate from './plugins/vuelidate'
import toast from './plugins/toast'
import axios from 'axios'

dotenvExpand(dotenv.config())

Vue.config.productionTip = false
Vue.component('v-icon', Icon)

// configure axios
axios.defaults.timeout = 20000 // default timeout
const HEADER_HQ_TOKEN = 'hq_token'
axios.interceptors.request.use(config => {
    // set sessionid as header in every request
    // if one is present
    var sessionData = Vue.getSessionData()
    if (sessionData && sessionData.token) {
        config.headers[HEADER_HQ_TOKEN] = sessionData.token
    }

    return config
})

// interceptors for to session
axios.interceptors.response.use(response => {
    // if the session from the response equals the current TO session
    // we made a successfull request to the tows and can update the
    // creation time of the sessionData so that the session will not end
    var sessionData = Vue.getSessionData()

    if (response.data.status && sessionData) {
        if (parseInt(response.data.status.ErrorCode) === 3) {
            // ErrorCode 3 --> session has expired
            this.$router.push({ name: 'login' })
        }

        var requestToken = response.data.status.SessionID
        var currentToken = sessionData.token

        if (requestToken == currentToken) {
            Vue.extendSession()
        }
    }

    return response
})

new Vue({
  router,
  toast,
  vuelidate,
  i18n,
  render: h => h(App),
}).$mount('#app')
