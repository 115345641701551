import Vue from 'vue'
import Router from 'vue-router'
import StorageManipulation from './StorageManipulation'
import LoginPage from '../pages/LoginPage.vue'
import ConfirmationPage from '../pages/ConfirmationPage.vue'
import NotFoundPage from '../pages/NotFoundPage.vue'
import ConfirmationLinkLandingPage from '../pages/ConfirmationLinkLandingPage.vue'


// register navigation
Vue.use(Router)
// register storage helper
Vue.use(StorageManipulation)

const options = {
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'root',
            meta: {
                noauth: true
            },
            // alway redirect to login first
            // routing then decides wether to show login or another site
            beforeEnter: (to, from, next) => {
                next({
                    path: '/login',
                    query: to.query
                })
            }
        },
        {
            path: '/login',
            name: 'login',
            component: LoginPage,
            meta: {
                noauth: true,
                menu: false,
                menuentry: false,
                display: 'Login'
            },
            beforeEnter: (to, from, next) => {
                // if there is already a session, redirect to dashboard
                if (Vue.getSessionData() !== undefined && !Vue.hasSessionExpired()) {
                    next('/dashboard')
                } else {
                    next()
                }
            }
        },
        {
            path: '/confirmation',
            name: 'confirmation',
            component: ConfirmationLinkLandingPage,
            meta: {
                noauth: true,
                menu: false,
                menuentry: false,
            }
        },

        // navbar is build in the same order as these entries
        {
            path: '/dashboard',
            name: 'dashboard',
            component: ConfirmationPage,
            meta: {
                menu: true
            }
        },
        // ----- DEFAULT ROUTE MUST BE LAST! -----
        {
            path: '*',
            name: 'notfound',
            component: NotFoundPage
        }
    ],
}

let router = new Router(options)

// check if the session is still valid
router.beforeEach((to, from, next) => {
    var sessionData = Vue.getSessionData()

    // always allow navigation to root and login
    // without any comment
    if (to.meta.noauth) {
        next()
        return
    } else if (sessionData === undefined) {
        next('/login')
    } else {
        // check if the session has ended
        // session timestamp is update everytime a call is made -> main.js axios interceptor
        if (Vue.hasSessionExpired()) {
            Vue.clearSessionData()
            Vue.$toast.info('Your session has expired')
            next('/login')
        } else {
            next()
        }
    }
})

export default router
